import React from "react";
import Icon from "@ant-design/icons";

const TrashSvg = () => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 641">
      <path
        id="Rectangle (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.01282 2.57227H12.9864V16.4583H1.01282V2.57227ZM2.46731 4.02675V15.0038H11.5319V4.02675H2.46731Z"
        fill="#2F66F2"
      />
      <path
        id="Path (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5452 4.06777H0.454834V2.61328H13.5452V4.06777Z"
        fill="#2F66F2"
      />
      <path
        id="Rectangle (Stroke)_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.00012 0.458984H10.4544V4.06827H3.00012V0.458984ZM4.45461 1.91347V2.61378H8.99989V1.91347H4.45461Z"
        fill="#2F66F2"
      />
      <path
        id="Path (Stroke)_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.57251 13.2441V5.78516H6.027V13.2441H4.57251Z"
        fill="#2F66F2"
      />
      <path
        id="Path (Stroke)_3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.07935 13.2441V5.78516H9.53383V13.2441H8.07935Z"
        fill="#2F66F2"
      />
    </g>
  </svg>
);

const NotificationTrashIcon = (props: any) => (
  <Icon component={TrashSvg} {...props} />
);

export default NotificationTrashIcon;
